<template>
   <div>
      <button :class="buttonClass" :disabled="isSaving" type="submit">
         {{ $t(`save`) }}
         <div v-if="isSaving" class="spinner-border spinner-border-sm float-right ml-1" role="status" aria-hidden="true"></div>
      </button>
   </div>
</template>

<script>
export default {
   name: "SaveButton",
   props: {
      buttonName: {
         type: String,
         required: true
      },
      isSaving: {
         type: Boolean,
         required: true
      }
   },
   computed: {
      buttonClass () {
         if (this.buttonName === 'edit')
            return 'btn btn-primary btn-sm text-bold';
         else
            return 'btn btn-success btn-sm text-bold';
      }
   }
}
</script>



<style scoped>

</style>
